import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`About me`}</h2>
    <ul>
      <li parentName="ul">{`22 yo (2002), Bac +3 (Bachelor's degree).`}</li>
      <li parentName="ul">{`Bachelor's Degree 1: Animation Cinema (2019-2022) at École Estienne, Paris, France.`}</li>
      <li parentName="ul">{`Bachelor's Degree 2: Motion Graphic Designer in work-study program (2022-2023) at Gobelins, Paris, France.`}</li>
    </ul>
    <p>{`Passionate about graphic design, motion design, and the latest generative AI technologies, I manipulate multimedia elements (images, footages, vectors, animations, 3D, VFX, SFX) to turn challenging situations into captivating visual solutions.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      